import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Container, CircularProgress, Box } from '@material-ui/core';
import { toast } from "react-toastify";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

const InicializarWhatsapp = () => {
  const history = useHistory();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState({});

  useEffect(() => {
    const authenticate = async () => {

      const getUrl = window.location.search;

      const urlParams = new URLSearchParams(getUrl);

      var sn_token = urlParams.get('token');
      var rtk = urlParams.get('rtk');

      if (sn_token) {

        try {
          const { data } = await api.post("/auth/login", { sn_token });
          localStorage.setItem("token", JSON.stringify(data.token));

          localStorage.removeItem("tk");
          sessionStorage.removeItem("tk");
          localStorage.removeItem("tokensalas");
          localStorage.removeItem("id_empresa");
          localStorage.setItem("tokensalas", data.tokensalas);
          localStorage.setItem("id_empresa", data.id_empresa);
          sessionStorage.setItem("tk", data.tokensessao);
          localStorage.setItem("tk", data.tokensessao);
          localStorage.setItem("empresaApelido", (data.empresaApelido));

          api.defaults.headers.Authorization = `Bearer ${data.token}`;
          setUser(data.user);
          setIsAuth(true);
          toast.success(i18n.t("auth.toasts.success"));

          if (!rtk) {

            history.push("/tickets");
          } else {

            history.push("/ticketchat/" + rtk + "?tk=" + data.tokensessao);
          }

          setTimeout(() => {
            window.location.reload();
          }, 100);
        } catch (err) {
          console.log(err);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(true);
      }
    };

    authenticate();
  }, [history]);

  if (loading) {
    return (
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <CircularProgress
            size={100}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container>

    </Container>
  );
};

export default InicializarWhatsapp;
