import api from "../../services/api";

const useQueues = () => {

    const tk = localStorage.getItem("tk");

	const findAll = async () => {
        const { data } = await api.get(`/queue?tk=${tk}`);
        return data;
    }

	return { findAll };
};

export default useQueues;
